import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following tables list the aggregation fields of Broadcast Contacts aggregation panel, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`Campaign Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External Campaign ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If it exists, identifier of external campaign from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaigns IDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of the identifiers of the external campaigns that handled the customer until the customer requested the contact.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      